<template>
  <div class="download-success-view">
    <PageLayout
      v-if="!isDownloading"
      :title="$t('loginAccountSuccess.title')"
    >
      <a
        class="simple-link"
        @click.prevent="onClickLink"
      >
        {{ $t('loginAccountSuccess.download.redirect') }}
      </a>
    </PageLayout>
    <PageLayout
      v-else
      :title="$t('appleDownloadView.title')"
    >
      <p class="download-success-view__content-text">
        {{ $t('appleDownloadView.subtitle') }}
      </p>
      <img
        class="download-success-view__background download-success-view__background--left"
        src="/assets/fs-left-bg.svg"
        alt="left background"
      >
      <img
        class="download-success-view__background download-success-view__background--right"
        src="/assets/fs-right-bg.svg"
        alt="left background"
      >
      <div class="download-success-view__architecture-selection">
        <button
          class="download-success-view__architecture-card"
        >
          <a
            class="download-success-view__architecture-button"
            :href="arm64DownloadUrl"
            referrerpolicy="unsafe-url"
            @click="onDownloadLinkClicked('arm64')"
          >
            <AppleMXIcon />
            <span>{{ $t('appleDownloadView.downloadARM') }}</span>
          </a>
          <div class="download-success-view__architecture-bg" />
        </button>
        <button
          class="download-success-view__architecture-card"
        >
          <a
            class="download-success-view__architecture-button"
            :href="intelDownloadUrl"
            referrerpolicy="unsafe-url"
            @click="onDownloadLinkClicked('intel')"
          >
            <AppleIntelIcon />
            <span>{{ $t('appleDownloadView.downloadIntel') }}</span>
          </a>
          <div class="download-success-view__architecture-bg" />
        </button>
      </div>
      <p class="download-success-view__help-center">
        {{ $t('appleDownloadView.helpCenterPhrase') }}
        <a href="https://voicemodmac.zendesk.com/hc/en-us/articles/9327214806546">
          {{ $t('appleDownloadView.helpCenterLink') }}
        </a>
      </p>
    </PageLayout>
  </div>
</template>

<script>
import { mParticleMetric } from '@/metrics';
import { CookieService } from '@/services/cookie';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { URL_CONSTANTS } from '@/externalURLs';
import { metricsService } from  '@/metrics/metrics.js';
import PageLayout from '@/components/PageLayout/PageLayout.vue';
import AppleMXIcon from '@/components/AppleMXIcon/AppleMXIcon.vue';
import AppleIntelIcon from '@/components/AppleIntelIcon/AppleIntelIcon.vue';
import * as Sentry from '@sentry/vue';
import { DownloadClient } from '@/services/download';

export default {
    components: {
        PageLayout,
        AppleMXIcon,
        AppleIntelIcon,
    },
    $URL_CONSTANTS: URL_CONSTANTS,
    data() {
        return {
            isDownloading: false,
            userId: null,
            isNewUser: false,
            arm64DownloadUrl: null,
            intelDownloadUrl: null,
            downloadInfo: null,
        };
    },
    async mounted() {
        metricsService.initMetrics();
        this.userId = this.$route.query.userId;
        this.isNewUser = this.$route.query.isNew === 'true';
        const cookieUserId = CookieService.getCookie('userId');
        if (this.userId !== cookieUserId) {
            this.$router.push({
                path: ROUTER_PATHS.HOME,
                query: {
                    action: LOGIN_ACTIONS_CONSTANTS.DOWNLOAD_FLAGSHIP,
                },
            });
        }

        // Look for userId on the left and redirect if not available
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('userId') === null) {
            searchParams.append('userId', this.userId);
            window.location.assign(`${location.origin}/?${searchParams}${location.hash}`);
            return;
        }

        this.$store.dispatch('user_info_store/axn_setShowProfile', true);

        mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_VIEW);
        CookieService.setCookie('userId', this.userId);

        const START_DOWNLOAD_TIMEOUT = 2000;

        try {
            const { data } = await DownloadClient.getFlagshipAppDownloadInfo();
            this.downloadInfo = data;
            this.arm64DownloadUrl = data.arm64;
            this.intelDownloadUrl = data.x86_64;
            setTimeout(() => this.onDownloadStart(), START_DOWNLOAD_TIMEOUT);
        } catch (error) {
            Sentry.captureMessage('Can\' download Flagship');
            Sentry.captureException(error);
            mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                login_error_reason: 'flagship_request_to_download_endpoint_failed',
            });
            this.$router.push(ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR);
            return;
        }
    },
    methods: {
        onDownloadStart() {
            this.isDownloading = true;
            this.sendDownloadMetrics();
        },
        onClickLink() {
            this.isDownloading = true;
        },
        onDownloadLinkClicked(arch) {
            this.sendDownloadMetrics(arch);
            return false;
        },
        sendDownloadMetrics(arch) {
            // arm64 o intel
            if (mParticleMetric) {
                mParticleMetric.track(mParticleMetric.EVENTS.DOWNLOAD_TRIGGERED, { source: 'auto', cpu_architecture: arch });
            }
            if (window.dataLayer) {
                window.dataLayer.push({event: 'downloadTriggered'});
                window.dataLayer.push({
                    'event': 'Download Triggered',
                    'product': 'Flagship', // V2
                    'cpu_architecture': arch,
                    'app_version': this.downloadInfo.appVersion,
                    'file_name': arch === 'arm64' ? this.downloadInfo.arm64 : this.downloadInfo.x86_64,
                });
            }
        },
    },
};
</script>

<style src="./FlagshipDownloadAppleView.scss" lang="scss"></style>
