<template>
    <svg width="72" height="30" viewBox="0 0 72 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8189 7.24572C11.5699 3.44385 14.5751 0.306878 18.0287 0C18.5102 4.39858 14.1434 7.67194 11.8189 7.24572Z" fill="currentcolor"/>
        <path d="M20.3265 28.705C18.7502 30.2829 17.0291 30.0338 15.3724 29.2863C13.6191 28.5223 12.0106 28.4891 10.1609 29.2863C7.84467 30.3161 6.62223 30.0172 5.23894 28.705C-2.61043 20.3504 -1.45233 7.62755 7.45864 7.16248C9.63009 7.27875 11.1421 8.39159 12.4128 8.49124C14.3108 8.09262 16.1283 6.94656 18.155 7.09605C20.5838 7.29536 22.4175 8.29193 23.6238 10.0858C18.6054 13.1917 19.7957 20.0182 24.3959 21.9283C23.4791 24.4198 22.2888 26.8946 20.3104 28.7216L20.3265 28.705Z" fill="currentcolor"/>
        <path d="M50.6297 29.1177V7.49269H46.7333L39.7498 24.5619H39.6299L32.6314 7.49269H28.75V29.1177H31.8071V13.2024H31.912L38.446 28.9529H40.9337L47.4527 13.2024H47.5576V29.1177H50.6297Z" fill="currentcolor"/>
        <path d="M53.2823 29.1177H56.9989L62.4538 20.8753H62.5587L67.9388 29.1177H71.8651L64.6718 18.3876V18.2977L72 7.49269H68.2085L62.8135 15.855H62.6936L57.3585 7.49269H53.3572L60.4607 18.1778V18.2827L53.2823 29.1177Z" fill="currentcolor"/>
    </svg>
</template>

<script>
export default {
    name: 'appleMX',
};
</script>
